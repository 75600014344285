<template>
  <b-overlay :show="flagShowOverlay">
    <div id="app" class="h-100" :class="[skinClasses]">
      <component :is="layout">
        <router-view />
      </component>
    </div>
    <ModalInsertAccountPinTemplate
      v-if="flagShowModalPin"
      :is-inative-modal="true"
      @resetInactivityTime="resetInactivityTime()"
    />
    <template #overlay>
      <div class="text-center d-flex flex-column align-items-center">
        <load-spinner class="load-spinner-global" />
      </div>
    </template>
  </b-overlay>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { closeTooltips } from "@core/utils/utils";

import { useWindowSize, useCssVar } from "@vueuse/core";

import store from "@/store";
import { BOverlay } from "bootstrap-vue";
import router from "@/router";
import { mapGetters, mapMutations } from "vuex";
import ModalInsertAccountPinTemplate from "@/@core/components/b-modal/ModalChangeAccount/ModalInsertAccountTemplate.vue";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");

export default {
  components: {
    // Layouts
    LoadSpinner: () => import("@/assets/images/pages/load-icon.svg"),
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    BOverlay,
    ModalInsertAccountPinTemplate,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    ...mapGetters("app", [
      "flagShowOverlay",
      "flagShowModalPin",
      "flagOverlayModalPin",
    ]),
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
    userInfocookie() {
      return this.$cookies.get("userInfo");
    },
  },
  data() {
    return {
      timeoutFunction: null,
      timeoutOverlay: null,
      isFirstLoading: true,
      resource: null,
      userEmail: "",
      prefix: this.$router.currentRoute.params.prefix,
    };
  },
  watch: {
    $route(to, _) {
      this.inactivityTime();
    },
    flagShowOverlay(value) {
      this.$router.currentRoute.path.includes("operator") &&
        this.resetInactivityTime();

      const isChangeAccount =
        this.$router.currentRoute.name === "change-account";

      if (
        !value &&
        localStorage.getItem("blockScreen") === "true" &&
        this.isFirstLoading &&
        !isChangeAccount
      ) {
        this.isFirstLoading = false;

        this.UPDATE_FLAG_SHOW_MODAL_PIN(true);
      }
    },
    userInfocookie: {
      handler() {
        this.verifyConnection(true);
      },
      deep: true,
    },
  },
  created() {
    closeTooltips(window);
    localStorage.setItem("requestList", JSON.stringify([]));

    const locale = this.$cookies.get('userInfo').language;
    this.$i18n.locale = locale;
    router.beforeEach((to, from, next) => {
      if (to.name != from.name) this.UPDATE_FLAG_SHOW_OVERLAY(true);

      localStorage.setItem("requestList", JSON.stringify([]));
      next();
    });
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  beforeMount() {
    const userInfo = this.$cookies.get("userInfo");
    this.UPDATE_FLAG_SHOW_OVERLAY(true)
    const hasPermission = userInfo?.userData.permissions.systems.find(
      (e) => e.name === 'mysfc'
    );
    if (!hasPermission) {
      this.$emit('logout');
      window.location.href = `${this.$myIndLink}login`;
    }
    const reloaded = localStorage.getItem('reloaded');
    if (reloaded !== 'true') {
      localStorage.setItem('reloaded', 'true');
      location.reload();
    } else {
      this.UPDATE_FLAG_SHOW_OVERLAY(false);
    }
  },
  mounted() {
    this.verifyConnection();
  },
  methods: {
    ...mapMutations("app", [
      "UPDATE_FLAG_SHOW_OVERLAY",
      "UPDATE_FLAG_SHOW_MODAL_PIN",
      "UPDATE_FLAG_OVERLAY_MODAL_PIN",
    ]),
    verifyConnection(v) {
      const userInfo = this.$cookies.get("userInfo");
      const hasPermission = userInfo?.userData.permissions.systems.find(
          (e) => e.name === 'mysfc'
        );

      if (userInfo && hasPermission) {
        const infoAccount = {
          guid: userInfo.userData.guid,
          employee_id: userInfo.userData.employee_id,
          first_name: userInfo.userData.first_name,
          last_name: userInfo.userData.last_name,
          job_role: userInfo.userData.job_role,
          photo: userInfo.userData.photo,
        };
        this.$ability.update(userInfo.userData.ability);
        this.userEmail = userInfo.userData.email;
        let tenant = this.userEmail.split("@");
        this.prefix = this.prefix ? this.prefix : tenant[1].split(".")[0];
        localStorage.setItem("currentSite", userInfo.currentSite);
        localStorage.setItem("accessToken", userInfo.accessToken);
        localStorage.setItem("userData", JSON.stringify(userInfo.userData));
        localStorage.setItem("prefix", this.prefix);
        localStorage.setItem("sites", JSON.stringify(userInfo.userData.sites));
        localStorage.setItem("infoAccountsLogin", JSON.stringify(infoAccount));
        if (localStorage.getItem("loggedAccountsInfo")) {
          if (
            !JSON.parse(localStorage.getItem("loggedAccountsInfo")).find(
              (item) => item.guid == userInfo.userData.guid
            )
          ) {
            var guidAtual = JSON.parse(
              localStorage.getItem("loggedAccountsInfo")
            );
            guidAtual.unshift(infoAccount);
            localStorage.setItem(
              "loggedAccountsInfo",
              JSON.stringify(guidAtual)
            );
          }
        } else {
          localStorage.setItem(
            "loggedAccountsInfo",
            JSON.stringify([infoAccount])
          );
        }

        const pcpAccess =
          JSON.parse(localStorage.getItem("userData")).ability.find(
            (element) => element.action == "manage"
          ) ||
          JSON.parse(localStorage.getItem("userData")).ability.find(
            (element) => element.subject == "pcp"
          );

        const isOperatorPage = window.location.pathname.includes('operator') || window.location.pathname.includes("change-account")

        if (!pcpAccess && v && !isOperatorPage) {
          this.$router.push({
            name: "operator",
            params: { prefix: this.prefix },
          });
        }

        if(pcpAccess && v && !isOperatorPage){
          this.$router.push({
            name: "home",
            params: { prefix: this.prefix },
          });
        }
      } else {
        this.$emit("logout");
        window.location.href = `${this.$myIndLink}login`;
      }
    },
    inactivityTime() {
      const isOperatorPage =
        this.$router.currentRoute.path.includes("operator");

      if (isOperatorPage) {
        window.addEventListener("mousemove", this.resetInactivityTime);
        window.addEventListener("touchend", this.resetInactivityTime);
        window.addEventListener("touchmove", this.resetInactivityTime);
        window.addEventListener("keydown", this.resetInactivityTime);
        this.resetInactivityTime();
        return;
      }

      window.removeEventListener("mousemove", this.resetInactivityTime);
      window.removeEventListener("touchend", this.resetInactivityTime);
      window.removeEventListener("touchmove", this.resetInactivityTime);
      window.removeEventListener("keydown", this.resetInactivityTime);
      clearTimeout(this.timeoutFunction);
      clearTimeout(this.timeoutOverlay);
    },
    resetInactivityTime() {
      clearTimeout(this.timeoutFunction);
      clearTimeout(this.timeoutOverlay);

      const requestList = JSON.parse(localStorage.getItem("requestList"));
      if (requestList.length > 0) return;

      let milliseconds = Number(localStorage.getItem("time_to_lock")) * 1000;
      // para ajudar a testar e desenvolver sem a modal pin
      if (
        localStorage.getItem("modal") === "off" &&
        (window.location.hostname.includes("dev") ||
          window.location.hostname.includes("localhost"))
      )

      this.timeoutOverlay =
        localStorage.getItem("blockScreen") === "true" &&
        this.flagOverlayModalPin
          ? setTimeout(
              () => this.addOverlayModalPinAndOverlayNavbar(),
              milliseconds
            )
          : null;

      if (localStorage.getItem("blockScreen") === "true") return;

      $("body").removeClass("stop-scrolling");

      this.timeoutFunction = setTimeout(() => {
        if (!this.$router.currentRoute.path.includes("operator")) return;

        const requestListInTheTimeout = JSON.parse(
          localStorage.getItem("requestList")
        );
        if (requestListInTheTimeout.length > 0)
          return this.resetInactivityTime();
        localStorage.setItem("lastModule", localStorage.getItem("mode"));
        localStorage.setItem("blockScreen", true);
        localStorage.removeItem("accessToken");

        this.addOverlayModalPinAndOverlayNavbar();
        this.UPDATE_FLAG_SHOW_MODAL_PIN(true);
        $("body").addClass("stop-scrolling");
      }, milliseconds || 30000);
    },
    addOverlayModalPinAndOverlayNavbar() {
      this.UPDATE_FLAG_OVERLAY_MODAL_PIN(true);
      $(".header-navbar").addClass("overlay-navbar");
    },
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses,
    };
  },
};
</script>

<style lang="scss">
body {
  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cfc4be;
    border-radius: 9px;
  }
}

.load-spinner-global {
  height: 50px !important;
  width: 50px !important;
  circle {
    fill: #974900 !important;
  }
}

.b-overlay {
  z-index: 9999999 !important;
  .position-absolute {
    &.bg-light {
      min-height: 100dvh;
      background: rgba(235, 235, 235, 0.8) !important;
      backdrop-filter: blur(20px) !important;
      opacity: 1 !important;
    }

    &:nth-child(2) {
      position: fixed !important;
      top: 50dvh !important;
      left: 0;
    }
  }
}

.overlay-navbar {
  opacity: 0.7;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden !important;
}

* {
  font-family: "Raleway", sans-serif !important;
  font-feature-settings: "pnum" on, "lnum" on;
  font-style: normal;
}

.swal-default {
  z-index: 999999999 !important;
  .swal2-modal {
    padding: 0 !important;
    border-radius: 6px;
    width: 710px;
    .swal2-close {
      color: #4c4541 !important;
      font-size: 2em;
    }
    .swal2-header {
      background-color: #d32f2f;
      padding: 20px 16px;
      border-radius: 6px 6px 0px 0px;
      flex-direction: row;
      justify-content: left;

      .swal2-title {
        color: #ffff;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        margin: 0;
      }

      .swal2-close {
        border-radius: 6px;
        width: 34px;
        background-color: #ffff;
        height: 34px;
        top: -5px;
        position: absolute;
        right: -5px;
      }
    }
    .swal2-content {
      padding: 20px 16px;

      .swal2-html-container {
        font-weight: 400;
        font-size: 14px;
        text-align: left;
        line-height: 20px;
        color: #4c4541;
        display: flex !important;
      }
    }
    .swal2-actions {
      padding: 0 16px 20px 16px;
      display: flex;
      justify-content: right;
      .swal2-confirm {
        margin-left: 10px !important;
        order: 2;
        font-weight: 600;
        font-size: 17px;
        line-height: 20px;
        color: #ffff;
        border-color: #974900 !important;
        background-color: #974900 !important;
        border-radius: 5px;

        &:hover {
          box-shadow: none !important;
        }
      }
      .swal2-cancel {
        order: 1;
        font-weight: 600;
        font-size: 17px;
        line-height: 20px;
        color: #974900 !important;
        border-radius: 5px;
        border-color: transparent !important;

        &:hover {
          background-color: #ffede1 !important;
        }
      }
    }
  }
}
</style>
